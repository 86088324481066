<template>
    <div class="card p-5">
        <HeaderPanel :tittle="'Produtividade coletiva'" :loading="loading" nomeTelaDoManual="relatorios-e-indicadores-produtividade-coletiva"/>
        <div class="col pr-0" >
            <div class="flex justify-content-end flex-row h-full">
                <div class="flex align-items-end">
                    <Button type="button" label="Recarregar" class="h-3rem"
                        @click="loadAllComponentes" icon="pi pi-sync" loadingIcon="pi pi-sync pi-spin" :loading="loading" />
                </div>
            </div>
        </div>
        <div class="layout-content">
            <div class="grid h-auto">
                <div
                    class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 20rem">
                        <SlaParaAgendarChart ref="slaParaAgendarChart" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 20rem">
                        <SlaTempoDeAgendamentoChart ref="slaTempoDeAgendamentoChart" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeAgendamentosManuaisEAutomaticosChart
                            ref="quantidadeAgendamentosManuaisEAutomaticosChart" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderPanel from '../components/HeaderPanel.vue';
import SlaParaAgendarChart from './components/SlaParaAgendarChart.vue';
import SlaTempoDeAgendamentoChart from './components/SlaTempoDeAgendamentoChart.vue';
import QuantidadeAgendamentosManuaisEAutomaticosChart from './components/QuantidadeAgendamentosManuaisEAutomaticosChart.vue';

export default {
    components: {
        HeaderPanel,
        SlaParaAgendarChart,
        SlaTempoDeAgendamentoChart,
        QuantidadeAgendamentosManuaisEAutomaticosChart
    },
    data() {
        return {
            loading: false,
        };
    },
    async mounted() {
        await this.loadAllComponentes();
    },
    methods: {
        async loadAllComponentes() {
            this.loading = true;
            try {
                await Promise.all([
                    this.$refs.slaParaAgendarChart.queryData(),
                    this.$refs.slaTempoDeAgendamentoChart.queryData(),
                    this.$refs.quantidadeAgendamentosManuaisEAutomaticosChart.queryData(),
                ]);
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'info', summary: 'Ops!', detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!', life: 3000 });
                this.loading = false;
            }
        }
    }
};
</script>
<style scoped>
.card {
    background: #ffffff;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #F4F4F4;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}
</style>
