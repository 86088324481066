<template>
    <div>
        <HeaderCard
            :loading="loading"
            tittle="Agendamentos"
            @onLoad="load"
            legenda="Comparação de solicitações no mês convertidas em agendamentos automáticos ou manual."
        />
        <Chart type="line" :data="stackedData" :options="stackedOptions" class="h-28rem" :plugins="plugins" />
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import moment from 'moment-timezone';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false,
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 25;
                        };
                    }
                }
            ]
        };
    },
    components: {
        HeaderCard
    },
    computed: {
        stackedData() {
            const data = {
                labels: this.dados
                    .map((item) => this.formatarData(item.quebra))
                    .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []),
                datasets: [
                    {
                        label: 'Total',
                        data: this.getQuantidadeTotal(),
                        backgroundColor: ['##EF2595'],
                        borderColor: '##EF2595',
                        tension: 0.2
                    },
                    {
                        label: 'Manual',
                        data: this.getQuantidadeManual(),
                        backgroundColor: ['#FF6384'],
                        borderColor: '#FF6384',
                        tension: 0.4
                    },
                    {
                        label: 'Automático',
                        data: this.getQuantidadeAutomatico(),
                        backgroundColor: ['#36A2EB'],
                        borderColor: '#36A2EB',
                        tension: 0.4
                    },
                ]
            };
            return data;
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        backgroundColor: '#495057',
                        borderRadius: 2,
                        color: 'white',
                        font: {
                            weight: 'bold'
                        },
                        padding: 8
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            };
        },
    },
    methods: {
        getQuantidadeManual() {
            const dados = this.dados.filter((p) => p.tipo == 'MANUAL');
            const arrayQuantidade = [];
            const labels = this.dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            const labelsDados = dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            for (const quebra of labels) {
                if (labelsDados.includes(quebra)) {
                    const dadoQuantidade = dados.find((p) => this.formatarData(p.quebra) == quebra).quantidade;
                    arrayQuantidade.push(dadoQuantidade?.toLocaleString('pt-BR'));
                    continue;
                } else {
                    arrayQuantidade.push('0');
                    continue;
                }
            }
            return arrayQuantidade;
        },
        getQuantidadeAutomatico() {
            const dados = this.dados.filter((p) => p.tipo == 'AUTOMATICO');
            const arrayQuantidade = [];
            const labels = this.dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            const labelsDados = dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);
            for (const quebra of labels) {
                if (labelsDados.includes(quebra)) {
                    const dadoQuantidade = dados.find((p) => this.formatarData(p.quebra) == quebra).quantidade;
                    arrayQuantidade.push(dadoQuantidade?.toLocaleString('pt-BR'));
                    continue;
                } else {
                    arrayQuantidade.push('0');
                    continue;
                }
            }
            return arrayQuantidade;
        },
        getQuantidadeTotal() {
            const dadosManual = this.dados.filter((p) => p.tipo === 'MANUAL');
            const dadosAutomatico = this.dados.filter((p) => p.tipo === 'AUTOMATICO');

            const arrayQuantidade = [];
            const labels = this.dados
                .map((item) => this.formatarData(item.quebra))
                .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []);

            for (const quebra of labels) {
                const quantidadeManual = dadosManual.find((p) => this.formatarData(p.quebra) === quebra)?.quantidade || 0;
                const quantidadeAutomatico = dadosAutomatico.find((p) => this.formatarData(p.quebra) === quebra)?.quantidade || 0;
                const totalQuantidade = parseInt(quantidadeManual) + parseInt(quantidadeAutomatico);

                arrayQuantidade.push(totalQuantidade?.toLocaleString('pt-BR') || 0);
            }
            return arrayQuantidade;
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get(
                    '/indicadores/agendamentos/produtividade-coletiva/comparativo-agendamentos-automaticos-manuais',
                    {
                        params: {
                            filtros: JSON.stringify({
                                periodicidade: this.periodicidade,
                                dataInicio: this.dataInicio,
                                dataFim: this.dataFim
                            })
                        }
                    }
                );
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        formatarData(data) {
            let formato;
            if (this.periodicidade === 'day') formato = 'DD/MM/YY';
            if (this.periodicidade === 'month') formato = 'MMM/YY';
            if (this.periodicidade === 'year') formato = 'YYYY';

            return moment.tz(data, 'UTC').format(formato);
        }
    }
};
</script>
